<template>
  <main class="main" data-view="tnc">
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="title tw-600 ts-26 ts-sm-34 ts-lg-40 mb-4">Obchodní podmínky</h1>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3">Tyto obchodní podmínky ve smyslu § 1751 odst. 1 občanského zákoníku (dále jen „obchodní podmínky“) upravují smluvní vztahy mezi společností MyCoiner s.r.o. se sídlem Na Folimance 2155/15, 120 00, Praha 2, IČO: 141 82 165, zapsané v obchodním rejstříku Městského soudu v Praze pod spisovou značkou C 361712 (dále jen „Poskytovatel“) a fyzickými a právnickými osobami (dále jen „Klient“), které budou využívat služby Poskytovatele spočívající ve vedení virtuálních kryptoměnových peněženek a nákupu,  prodeji, převodu  a směně virtuálních měn prostřednictvím aplikace/webového rozhraní provozovaného Poskytovatelem. Veškeré služby budou poskytovány výhradně prostřednictví aplikace/ webového rozhraní na internetových stránkách www.mycoiner.cz. Poskytovatel je osobou poskytující služby spojené s virtuální měnou, a to v návaznosti na živnostenské oprávnění vydané příslušným živnostenským úřadem. Poskytovatel je povinnou osobou ve smyslu § 2 odst. 1 písm. l) zák. č. 253/2008 Sb. a jeho činnost spadá pod dohled Finančního analytického útvaru.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-4">Znění obchodních podmínek může Poskytovatel jednostranně měnit či doplňovat. Tímto ustanovením nejsou dotčena práva a povinnosti vzniklá po dobu účinnosti předchozího znění obchodních podmínek.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text tw-500 lh-35 ts-18 ts-md-24 mb-3">1. Uzavření smlouvy</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">1.1.</span> Po registraci Klienta mu bude Poskytovatelem zřízena virtuální kryptoměnová peněženka. Virtuální kryptoměnovou peněženkou se rozumí nástroj pro správu adres umožňujících vklad a privátních klíčů pro operace s virtuální měnou. Vkladové adresy se mohou libovolně zveřejňovat a lze na ně virtuální měny přijímat. Registrací klienta se Poskytovatel zavazuje vést klientovi tuto virtuální kryptoměnovou peněženku, převádět, připisovat a směňovat virtuální měny dle pokynů klienta a poskytovat mu výpisy z této kryptoměnové peněženky.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">1.2.</span> Poskytovatel se zavazuje realizovat pokyny klienta spočívající ve směně virtuální měny za jinou virtuální měnu, tyto směny se Poskytovatel zavazuje realizovat v reálném čase, bezprostředně po zadání pokynu, kdy všechny virtuální měny budou uchovávány na kryptoměnové peněžence Klienta. Za uvedenou službu se zavazuje klient zaplatit poplatek dle sazebníku Poskytovatele. Tento Sazebník poplatků je nedílnou součástí těchto obchodních podmínek. Poplatek je určen podílem ze směňované měny.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">1.3.</span> Nákup virtuálních měn za peníze (CZK, EUR) bude realizován prostřednictvím bankovního účtu ve Fio Bance č.ú. 2502112407/2010, vedeného na jméno Poskytovatele. Poskytovatel se zavazuje pro Klienta nakoupit a na jeho kryptoměnovou peněženku připsat Klientem zvolenou virtuální měnu odpovídající finanční částce deponované klientem na tento bankovní účet, a to dle kurzu této virtuální měny, který bude aktuální v okamžiku připsání peněz na bankovní účet definovaný v tomto článku. Za uvedenou službu se zavazuje klient zaplatit poplatek dle sazebníku Poskytovatele. Poplatek bude odečten Poskytovatelem z Klientem převedené částky před realizací nákupu virtuální měny.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3">K uzavření smlouvy o nákupu virtuální měny mezi Klientem a Poskytovatelem dochází v okamžiku připsání peněz na účet Poskytovatele ve Fio Bance č.ú. 2502112407/2010. Tomuto uzavření smlouvy a připsání peněz předchází zadání objednávky Klienta v aplikaci/webovém rozhraní Poskytovatele, kde si Klient zvolí virtuální měnu, kterou si přeje za své finanční prostředky nakoupit a definuje objem peněz, za něž si tento nákup přeje realizovat. Této objednávce musí předcházet registrace Klienta, jeho ověření a otevření kryptoměnové peněženky. Objednávkou se rozumí vyplnění údajů ve formuláři na webovém rozhraní/aplikaci Poskytovatele, které jsou třeba k jednoznačné specifikaci přání Klienta. Jakmile je proveden bankovní převod peněz na účet Poskytovatele specifikovaný výše je Poskytovatelem proveden nákup Klientem zvolené virtuální měny dle kurzu aktuálního v čase připsání peněz na bankovní účet Poskytovatele. Smlouva je ze strany Poskytovatele splněna okamžikem připsání virtuální měny na adresu kryptoměnové peněženky, která je Poskytovatelem vedena pro Klienta.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">1.4.</span> K uzavření smlouvy o prodeji virtuální měny mezi Klientem a Poskytovatelem dojde v okamžiku, kdy Klient zadá pokyn k prodeji virtuální měny, která je v držení Klienta na kryptoměnové peněžence vedené Poskytovatelem. Pokynem k prodeji se rozumí vyplnění údajů ve formuláři na webovém rozhraní/aplikaci Poskytovatele, které jsou nezbytné k jednoznačné specifikaci přání Klienta, zejména uvedení druhu a množství prodávané virtuální měny. Smlouva je uzavřena okamžikem potvrzení transakce tlačítkem ve webovém rozhraní nebo aplikaci, kterým se prodej aktivuje. Jakmile je uzavřena smlouva o prodeji virtuální měny Poskytovatel převede na bankovní účet Klienta, který je veden a evidován na jméno klienta, finanční prostředky, dle množství prodávané virtuální měny a aktuálního kurzu této virtuální měny. Za uvedenou službu se zavazuje Klient zaplatit poplatek dle sazebníku Poskytovatele. Poplatek bude odečten Poskytovatelem z Klientovi převáděné částky před převodem peněz na bankovní účet Klienta. </p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">1.5.</span> Poskytovatel se zavazuje realizovat pokyny klienta spočívající v převodu virtuální měny z Poskytovatelem vedené kryptoměnové peněženky na adresu jiné kryptoměnové peněženky, tyto převody se Poskytovatel zavazuje realizovat bezprostředně po zadání pokynu, kdy za přesné zadání adresy kryptoměnové peněženky plně odpovídá klient. Poskytovatel nemá žádnou odpovědnost za případnou chybu této adresy a převod virtuální měny na jinou kryptoměnovou peněženku nelze u Poskytovatele nijak reklamovat. Za uvedenou službu se zavazuje klient zaplatit poplatek dle sazebníku Poskytovatele. Tento Sazebník poplatků je nedílnou součástí těchto obchodních podmínek. Poplatek je určován podílem z převáděné měny.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">1.6.</span> Kurz kryptoměny, dle kterého je realizován nákup, prodej či směna kryptoměny je určován výhradně Poskytovatelem. Vývoj kurzu kryptoměny pro účely poskytování služeb dle těchto obchodních podmínek je Poskytovatelem archivován. Prodejní a nákupní kurzy kryptoměny jsou ve stejný okamžik odlišné, kdy rozdíl mezi těmito kurzy je tzv. spread. Rozsah spreadu určuje Poskytovatel dle svého uvážení a může jej libovolně měnit, a to zejména v závislosti na likviditě a volatilitě trhu s kryptoměnami. Klient si je vědom skutečnosti, že kurz kryptoměny se tak může zásadně měnit v závislosti na denní době, dnu v týdnu či dění na trhu s kryptoměnami, a to i velmi dynamicky. Klient bere na vědomí a je si vědom skutečnosti, že při nákupu kryptoměny dle čl. 1.3. těchto obchodních podmínek může dojít ke změně kurzu i v době od okamžiku zadání objednávky do okamžiku připsání peněž na účet Poskytovatele (a to i změnou rozsahu spreadu z rozhodnutí Poskytovatele). V rámci vypořádání objednávky pro nákup kryptoměny je vždy rozhodný kurz určený Poskytovatelem pro nákup kryptoměny v okamžiku připsání peněz na bankovní účet Poskytovatele.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">1.7.</span> Poskytovatel upozorňuje Klienta, že po provedení nákupu, prodeje, převodu či směny virtuální měny není možné od uzavřené smlouvy (objednávky, pokynu) odstoupit, protože nákup virtuální měny naplňuje podmínky §1837 písm. a), l) občanského zákoníku. </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text tw-500 lh-35 ts-18 ts-md-24 mb-3">2. Závazné prohlášení a povinnosti klienta</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">2.1.</span> Klient prohlašuje, že si je vědom, že virtuální měny nejsou v České republice zákonnými měnami, což znamená, že virtuální měny, ani služby na ně vázané, nejsou v České republice regulovány právními předpisy upravujícími platební služby a nespadají tak pod regulaci a dohled České národní banky.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">2.2.</span> Klient prohlašuje, že si je vědom toho, že virtuální měny jsou vysoce volatilní a jsou tak spojeny s vysokou míru rizika. Kurzy virtuálních měn vůči zákonným měnám, jakož i vůči sobě mohou zaznamenat prudké výkyvy i v průběhu několika hodin. </p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">2.3.</span> Klient prohlašuje, že si je vědom, že hodnota a možnost využití virtuálních měn v reálném světě je založena na vývoji technologie a důvěře v tuto technologii. Není žádná centrální banka či jiná autorita, která by regulovala nebo korigovala vývoj kurzu virtuálních měn či jakkoli ochránila jejich hodnotu.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">2.4.</span> Klient prohlašuje, že si je vědom toho, že virtuální měny jsou zcela autonomní a neregulované světové systémy speciální sítě firem a jednotlivců. K zásadním změnám ve fungování virtuálních měn může dojít v souvislosti s jejich regulací, technologickým pokrokem či jinou okolností nepředvídanou v těchto Obchodních podmínkách. Klient na sebe přejímá veškerá rizika spojená s obchodováním s virtuální měnou a změnami spočívajícími v regulaci, technologickém vývoji či v těchto obchodních podmínkách nepředvídanými situacemi. </p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">2.5.</span> Klient prohlašuje, že pečlivě zvážil veškeré okolnosti a své majetkové a osobní poměry, jeho finanční situace a schopnost posoudit riziko odpovídají povaze nákupu virtuální měny a do obchodního vztahu s Poskytovatelem vstupuje po pečlivém uvážení. </p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">2.6.</span> Podmínkou využití služeb Poskytovatele je pochopení mechanismu nákupu a pochopení principu fungování virtuálních měn jako takových. Klient prohlašuje, že si je jistý svými znalostmi dané problematiky, chápe principy virtuálních měn, a fungování obchodování s nimi jakož i virtuálních kryptoměnových peněženek a nežádá žádných bližších vysvětlení obchodních mechanismů ani žádných další skutečností ze strany Poskytovatele</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">2.7.</span> Klient bere na vědomí, že Poskytovatel je oprávněn v souladu se zákonnými ustanoveními o zákazu provést obchod podle § 15 odst. 1 písm. b) zákona č. 253/2008 Sb. bez předchozího upozornění zavést objemová omezení (na jeden obchod nebo na součet obchodů) nebo omezení počtu provedených obchodů vztahujících se na jednoho klienta, na skupinu klientů nebo na konkrétní peněženku s virtuální měnou. </p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">2.8.</span> Klient prohlašuje, že jím zaslané peněžní prostředky nebo virtuální měny nepocházejí z výnosů z trestné činnosti a účelem nákupu, směny, převodu a prodeje virtuálních měn není legalizace výnosů z trestné činnosti či provedení transakcí směřujících k podpoře nebo financování terorismu ve smyslu příslušných ustanovení zákona č. 253/2008 Sb. Klient v této souvislosti bere na vědomí, že Poskytovatel postupuje podle zákona č. 253/2008 Sb. Klient prohlašuje, že zdrojem finančních prostředků či převáděných virtuálních měn je výhradně příjem z jeho podnikání nebo zaměstnání, půjčka, dar, výhra, dědictví či příjem z prodeje jiného majetku. Poskytovatel je oprávněn požadovat od Klienta doplnění získaných informací a dokladů.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">2.9.</span> Klient se zavazuje k uskutečňování obchodů používat výhradně své vlastní finanční prostředky, případně virtuální měnu patřící pouze jemu, nikoliv cizí osobě. Klient bere na vědomí, že Poskytovatel je na základě ustanovení zákona 253/2008 Sb. oprávněn přezkoumat zdroje peněžních prostředků Klienta. </p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">2.10.</span> Dále se klient zavazuje, že služeb Poskytovatele nevyužije k převodu majetkových hodnot osobě nebo od osoby, která má bydliště, sídlo, pobočku či provozovnu v zemích, které jsou považované z hlediska prevence legalizace výnosů z trestné činnosti nebo financování terorismu za rizikové nebo vysoce rizikové; seznam těchto zemí uveřejňuje a aktualizuje organizace FATF-GAFI a Klient prohlašuje, že je s těmito seznamy seznámen.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">2.11.</span> Klient se zavazuje uhradit škodu a veškeré náklady, které vzniknou Poskytovateli pokud škoda či náklady Poskytovatele vzniknou v návaznosti na jeho protiprávní jednání, zneužití služeb Poskytovatele či jiným jednáním v rozporu s dobrými mravy, zejména pak porušením povinností Klienta či jeho nepravdivými prohlášeními dle těchto Obchodních podmínek.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">2.12.</span> V případě, že na straně Klienta dojde ke zjištění jakýchkoliv nestandardních okolností, zejména zcizení či zneužití přístupových údajů ke Klientskému účtu, osobních dokladů, zařízení pro elektronickou komunikaci nebo jakýchkoliv jiných událostí, které ovlivnily nebo by mohly ovlivnit bezpečnost či správnost prováděných transakcí, je Klient povinen o takových skutečnostech neprodleně informovat Poskytovatele.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text tw-500 lh-35 ts-18 ts-md-24 mb-3">3. Reklamace</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">3.1.</span> Reklamace Klienta musí být učiněna prostřednictvím webového rozhraní na internetových stránkách poskytovatele, na jím vytvořeném reklamačním formuláři, a to do 5ti pracovních dnů od provedení transakce. Poskytovatel je povinen reklamaci Klienta vyřídit do 30 dní ode dne doručení reklamace. Informaci o vyřízení reklamace zašle Poskytovatel na elektronickou adresu Klienta.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">3.2.</span> Zásadně nelze provést reklamaci spočívající v nesprávnosti kurzu virtuální měny uvedeného na webových stránkách Poskytovatele či reklamaci spočívající v chybně zadané adrese kryptoměnové peněženky na níž byly převedena virtuální měna z pokynu zadaného Klientem. </p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">3.3.</span> Klient bere na vědomí, že Poskytovatel nemá žádnou odpovědnost za situaci, kdy jeho služby budou nedostupné z důvodu vzniklého nezávisle na vůli Poskytovatele. Poskytovatel nemá žádnou odpovědnost za nemožnost přijmout či realizovat pokyny Klienta z důvodu technického výpadku, výpadku energií, výpadku systému a konektivity či jiné objektivní nemožnosti přijmout a realizovat pokyn klienta.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">3.4.</span> Klient bere na vědomí, že služby poskytovatele nebudou dostupné v případě plánovaných odstávek systému.  </p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">3.5.</span> Klient bere na vědomí, že s ohledem na vlastnosti digitálního, decentralizovaného a částečně anonymního systému fungování virtuálních měn, se pro účely reklamací transakcí za nezpochybnitelné berou údaje z databází Poskytovatele, a to jak o kurzu tak i převodech virtuálních měn a Poskytovatelem vedené údaje jsou závazné pro účely vyřízení reklamací.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">3.6.</span> Klient bere na vědomí, že nákupy, prodeje a směny virtuálních měn jsou vždy realizovány dle aktuálního kurzu virtuálních měn zveřejněných na stránkách poskytovatele.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text tw-500 lh-35 ts-18 ts-md-24 mb-3">4. Ochrana osobních údajů</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">4.1.</span> Klient bere na vědomí, že Poskytovatel je povinen v rámci plnění svých zákonných povinností shromažďovat vybrané osobní údaje klienta a Klient provedením registrace, jakož i uzavřením smlouvy souhlasí s jejich uchovávání.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">4.2.</span> Klient výslovně rovněž uděluje Poskytovateli souhlas s využitím svých osobních a kontaktních údajů v rámci zasílání newsletteru, marketingových a jiných sdělení, jakož i jejich využitím v rámci ze strany partnerských společností v rámci affiliate programů.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text tw-500 lh-35 ts-18 ts-md-24 mb-3">5. Další práva a povinnosti stran</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">5.1.</span> Ve smyslu ustanovení § 1826 odst. 1 občanského zákoníku Poskytovatel sděluje, že Klientem provedená registrace a jeho objednávky a pokyny budou Poskytovatelem uchovávány a Klientovi k nim nebude umožněn přístup. Registrace jakož i pokyny a objednávky lze realizovat jen v českém jazyce. Poskytovatel není vůči Klientovi vázán žádnými kodexy chování.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">5.2.</span> K mimosoudnímu řešení spotřebitelských sporů ze smlouvy je příslušná Česká obchodní inspekce, se sídlem Štěpánská 567/15, 120 00 Praha 2, IČ: 000 20 869, internetová adresa: https://adr.coi.cz/cs. Dozor nad oblastí ochrany osobních údajů vykonává Úřad pro ochranu osobních údajů. Česká obchodní inspekce vykonává ve vymezeném rozsahu mimo jiné dozor nad dodržováním zákona č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">5.3.</span> Klient tímto přebírá na sebe nebezpečí změny okolností ve smyslu § 1765 odst. 2 občanského zákoníku.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">5.4.</span> Pokud vztah založený smlouvou obsahuje mezinárodní (zahraniční) prvek, pak strany sjednávají, že vztah se řídí českým právem. </p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">5.5.</span> Je-li některé ustanovení obchodních podmínek neplatné nebo neúčinné, nebo se takovým stane, namísto neplatných ustanovení nastoupí ustanovení, jehož smysl se neplatnému ustanovení co nejvíce přibližuje. Neplatností nebo neúčinností jednoho ustanovení není dotčena platnost ostatních ustanovení.</p>
            <p class="text tc-gray lh-35 ta-center ta-md-start mb-3"><span class="tw-500 tc-black">5.6.</span> Kontaktní údaje Poskytovatele: adresa pro doručování je adresa jeho sídla evidovaná v obchodním rejstříku, adresa elektronické pošty je info@mycoiner.cz, Poskytovateli i klientovi může být doručováno na elektronickou adresu.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text tc-gray lh-35 ta-center ta-md-start">Tyto Obchodní podmínky jsou platné a účinné od 1.2.2022, přičemž jsou k dispozici v sídle Poskytovatele a na jeho internetových stránkách.</p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  metaInfo () {
    const meta = this.$t('meta.documents.tnc')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        // { name: 'description', content: meta.description }
      ]
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
